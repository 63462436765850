import { BalloonGame } from "./Game";

document.addEventListener("DOMContentLoaded", (e) => {
	// eslint-disable-next-line no-new
	new BalloonGame();
});

export function printConfig(config: any) {
	let string = "";
	for (const [key, value] of Object.entries(config)) {
		string += key + "=" + value + "\n";
	}
	console.log(string);
}
