/**
 * Generic backgroundImage class
 */
type ImageTypes = 'plane' | 'cloud' | 'bird';

export class BackgroundImage{
    differntStartPos: any;
    positionX: number;
    positionY: number;
    speed: any;
    intervalId: any;
    game: any;
    onclick: any;
    el: any;
    leftToRight: any;
    type: ImageTypes;
    version: number;
    destroyed = false;
    scale: number = 1;
    sound?: HTMLAudioElement;
    constructor(game, type: ImageTypes, speed, differntStartPos, version?: number){
        this.differntStartPos = differntStartPos;
        this.positionX = this.getLeftOrRightStartPos();
        this.positionY = this.generaterandomYPos();
        this.speed = speed;
        this.intervalId = null;
        this.game = game;
        this.onclick = null;
        this.el = null;
        this.leftToRight;
        this.type = type;
        this.version = version;
    }

    getLeftOrRightStartPos(){
        var startPos = 0;
        if(this.differntStartPos){
            var canvasWidth = document.getElementById('canvas').offsetWidth;

            startPos =  Math.floor(Math.random() * canvasWidth);
        }else{
            var canvasWidth = document.getElementById('canvas').offsetWidth;
            var rand = Math.floor(Math.random() * Math.floor(2));

            switch(rand){
                case 0:
                    startPos = -(150 * this.scale);
                    this.leftToRight = true;
                    break;
                case 1:
                    startPos = canvasWidth + (150  * this.scale);
                    this.leftToRight = false;
                    break;
            }
        }
        return startPos;
    }

    generaterandomYPos(){
        var canvasHeight = document.getElementById('canvas').offsetHeight;
        canvasHeight = canvasHeight - 200;

        return Math.floor(Math.random() * canvasHeight);
    }

    updateIamgePosition(){

        if (this.game.isPaused) {
            return;
        }

        var destroyed = false;
        var left = parseFloat(this.el.style.left);

        if(this.leftToRight){
            this.el.style.left = left + this.speed + 'px';
            if(left > this.game.screenWidth + (100 * this.scale)){
                this.destroy();
                destroyed = true;
            }
        }
        else{
            this.el.style.left = left-this.speed + 'px';
            if(left < (-100 * this.scale)){
                this.destroy();
                destroyed = true;
            }
        }
        if(!destroyed){
            this.intervalId = requestAnimationFrame(this.updateIamgePosition.bind(this));
        }

    }

    /**
     * Spawn the image as an element on the screen.
     *
     */
    spawnOnCanvas(){
        this.el = document.createElement('div');
        if(this.type === 'cloud'){
            this.el.className = this.type + " type" + Math.round(Math.random() * 2 + 1);
        } else {
            this.el.className = this.type;
        }
        this.game.gameLog('create', this.el.className);
        this.el.style.left = this.positionX+'px';
        this.el.style.bottom = this.positionY+'px';
        let scale = 1;
        let opacity = 1;
        switch(this.el.className){
            case "bird":
                scale = this.game.config.birdScale;
                break;
            case "plane":
                scale = this.game.config.planeScale;
                break;
            case "cloud type1":
                scale = this.game.config.cloud1Scale;
                opacity = this.game.config.cloud1Opacity;
                break;
            case "cloud type2":
                scale = this.game.config.cloud2Scale;
                opacity = this.game.config.cloud2Opacity;

                break;
            case "cloud type3":
                scale = this.game.config.cloud3Scale;
                opacity = this.game.config.cloud3Opacity;

                break;
        }
        this.scale = scale;

        let direction = this.leftToRight ? 1 : -1;
        this.el.style.transform = "scaleX(" + direction * scale + ")" + " scaleY(" + scale + ")";
        this.el.style.opacity = opacity;
        // this.el.addEventListener("touchstart", (event) => {
        //     event.preventDefault();
        //     event.stopImmediatePropagation();
        //     return;
        // }, false);

        this.sound?.play();
        this.game.canvasElement.appendChild(this.el);
        if(!this.leftToRight){
            this.el.className += ' rtl';
        }
        requestAnimationFrame(this.updateIamgePosition.bind(this));
      }

    /**
     * Destroys the element.
     */
    destroy(){
        if (this.destroyed) {
            return;
        }

        this.destroyed = true;
        this.game.gameLog(this.el.className + ' destroyed!');
        cancelAnimationFrame(this.intervalId);
        this.game.canvasElement.removeChild(this.el);
        var directSpawn = false;
        if (this.type.indexOf('cloud') > -1) {
            this.type = 'cloud';
            directSpawn = true;
          }
        // this.game.imageSpawner(this.type, 1, directSpawn, false);
    }

    paused: boolean = false;

    pause(state: boolean) {
        this.paused = state;
        if (!this.paused) {
            requestAnimationFrame(this.updateIamgePosition.bind(this));
        }
    }
}
